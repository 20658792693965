@font-face {
  font-family: 'icomoon';
  src: url('./icomoon.eot');
  src: url('./icomoon.eot?#iefix') format('embedded-opentype'), url('./icomoon.woff') format('woff'),
    url('./icomoon.ttf') format('truetype'), url('./icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon', serif;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: '\e900';
}

.icon-search:before {
  content: '\e901';
}

.icon-close:before {
  content: '\e902';
}

.icon-notification:before {
  content: '\e903';
}

.icon-envelop:before {
  content: '\e904';
}

.icon-facebook:before {
  content: '\e905';
}

.icon-phone:before {
  content: '\e906';
}

.icon-filter:before {
  content: '\e907';
}

.icon-check:before {
  content: '\e908';
}

.icon-plus:before {
  content: '\e909';
}

.icon-delete:before {
  content: '\e90a';
}

.icon-user:before {
  content: '\e90d';
}

.icon-key:before {
  content: '\e90c';
}

.icon-logout:before {
  content: '\e90b';
}

