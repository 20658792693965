.recipe-item {
  &__image-wrapper {
    @apply overflow-hidden;

    img {
      @apply duration-300;
    }
  }

  &:hover {
    .recipe-item__image-wrapper {
      img {
        @apply scale-110;
      }
    }
  }
}
